@import 'helpers';

:root {
  @include colors;
  --ls-183: 0.183px;

  // margins
  --margin-xs: 8px;
  --margin-sm: 16px;
  --margin-md: 24px;
  --margin-lg: 32px;
  --margin-xl: 48px;
  --margin-xxl: 64px;

  --container-padding-mobile: 24px;
  --container-padding-portrait: 64px;
  --container-padding-landscape: 72px;
  --container-padding-desktopSmall: 120px;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  font-weight: normal;
}

body {
  @include setProperty(background, var(--dark-bg), var(--light-bg));
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  overflow-x: hidden;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

//autofill styles disabling
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    transition-delay: background-color 5000s, color 5000s;
}
a {
  color: inherit;
  text-decoration: none;
  font-family: inherit;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  @include setProperty(outline-color, var(--primary-500), var(--primary-900));
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

pre {
  margin: 0;
}

ul,
li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  font-family: inherit;
  text-align: inherit;
  cursor: pointer;
}

figure {
  margin: initial;
  padding: initial;
}

.sectionTitle {
  font-family: 'KharkivTone', sans-serif;
  font-size: 40px;
  line-height: 52px;
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  text-transform: uppercase;
  transition: none;

  @include media(desktopHuge) {
    //font-size: 80px;
    //line-height: 100px;
  }

  @include media(mobile) {
    font-size: 24px;
    line-height: 32px;
  }

  mark {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }
}

mark {
  background-color: transparent;
  color: inherit;
}


// now this sets the width and horizontal paddings,
// any change to this rule must be followed be regression testing of the whole app
.container {
  width: 100%;

  @include media(mobile) {
    padding-left: var(--container-padding-mobile) !important;
    padding-right: var(--container-padding-mobile) !important;
  }

  @include media(portrait) {
    padding-left: var(--container-padding-portrait) !important;
    padding-right: var(--container-padding-portrait) !important;
  }

  @include media(landscape) {
    padding-left: var(--container-padding-landscape) !important;
    padding-right: var(--container-padding-landscape) !important;
  }

  @include media(desktopSmall) {
    padding-left: var(--container-padding-desktopSmall) !important;
    padding-right: var(--container-padding-desktopSmall) !important;
  }

  @include media(desktop) {
    width: 1040px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  @include media(desktopBig) {
    width: 1280px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  @include media(desktopHuge) {
    //width: 1760px;
    width: 1280px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.title1 {
  font-size: 32px;
  line-height: 40px;
  font-family: 'KharkivTone', sans-serif;

  mark {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  @include media(desktopAll) {
    font-size: 60px;
    line-height: 68px;
    letter-spacing: 1px;
  }
}

.title2 {
  font-size: 32px;
  line-height: 40px;
  font-family: 'KharkivTone', sans-serif;

  mark {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  @include media(desktopAll) {
    font-size: 48px;
    line-height: 56px;
  }
}

.title3 {
  font-size: 28px;
  line-height: 36px;
  font-family: 'KharkivTone', sans-serif;

  mark {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  @include media(desktopAll) {
    font-size: 40px;
    line-height: 48px;
  }
}

.title4 {
  font-size: 24px;
  line-height: 32px;

  font-family: 'KharkivTone', sans-serif;

  mark {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  @include media(desktopAll) {
    font-size: 32px;
    line-height: 40px;
  }
}

.title6 {
  font-size: 16px;
  line-height: 24px;
  font-family: 'KharkivTone', sans-serif;

  mark {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  @include media(desktopAll) {
    font-size: 20px;
    line-height: 28px;
  }
}

.bodyText {
  font-size: 14px;
  line-height: 2;
  font-weight: 300;
  white-space: pre-wrap;

  @include media(desktopAll) {
    font-size: 16px;
    letter-spacing: var(--ls-183);
  }
}

.subtitle {
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  font-size: 20px;
  line-height: 32px;
  font-family: 'KharkivTone', sans-serif;

  @include media(desktopBig) {
    font-size: 24px;
    line-height: 32px;

    &.big {
      font-size: 40px;
      line-height: 52px;
    }
  }

  @include media(desktopHuge) {
    font-size: 32px;
    line-height: 1;

    &.big {
      font-size: 56px;
      line-height: 64px;
    }
  }
}

.LandbotLivechat {
  right: 32px !important;
  bottom: 32px !important;
  z-index: 9 !important;
}

.errorPage {
  @include pageHeaderPadding;
}

.MuiFormLabel-asterisk.Mui-error {
  @include setProperty(color, var(--dark-error), var(--light-error));
}

@font-face {
  font-family: 'Adjusted Arial';
  src: local(Arial);
  font-size-adjust: cap-height 0.68;
}

@include font-face("KharkivTone", "/fonts/KharkivTone/KharkivTone", 400);

//@include font-face("Montserrat", "/fonts/Montserrat/Montserrat-Black", 900);
//@include font-face("Montserrat", "/fonts/Montserrat/Montserrat-Extrabold", 800);
@include font-face("Montserrat", "/fonts/Montserrat/Montserrat-Bold", 700);
//@include font-face("Montserrat", "/fonts/Montserrat/Montserrat-SemiBold", 600);
@include font-face("Montserrat", "/fonts/Montserrat/Montserrat-Medium", 500);
@include font-face("Montserrat", "/fonts/Montserrat/Montserrat-Regular");
@include font-face("Montserrat", "/fonts/Montserrat/Montserrat-Light", 300);
//@include font-face("Montserrat", "/fonts/Montserrat/Montserrat-ExtraLight", 200);
//@include font-face("Montserrat", "/fonts/Montserrat/Montserrat-Thin", 100);
@include font-face("Mulish", "/fonts/Mulish/Mulish-Light", 400);
@include font-face("Mulish", "/fonts/Mulish/Mulish-SemiBold", 600);
